
import Vue from 'vue';

import BoxDemographicsGroupPie from '@/components/Box/Demographics/BoxDemographicsGroupPie.vue';
import BoxDemographicsGenderRate from '@/components/Box/Demographics/BoxDemographicsGenderRate.vue';
import BoxDemographicsTrends from '@/components/Box/Demographics/BoxDemographicsTrends.vue';
import { mapState, mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  components: {
    BoxDemographicsGenderRate,
    BoxDemographicsGroupPie,
    BoxDemographicsTrends
  },
  data() {
    return {
      lastTimeStamp: '',
      refreshInterval: null,
    };
  }, // data
  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getStartingPeriod',
      'getEndingPeriod',
    ]),
    ...mapGetters('user', ['getLocale'])
  },

  mounted() {
    Vue.prototype?.$mixpanel?.track('demographics_report', {
      referrer: document.referrer
    });

    this.startAutoRefresh();

},


  methods: {
    ...mapActions('demographics', ['fetchDemographicsFidaAll']),
    refreshPage() {

      this.lastTimeStamp = Date.now();
      this.clearAutoRefresh();

    if(this.lastTimeStamp != '')
    {
          this.fetchDemographicsFidaAll({
            storeCodes: this.getStoreCodesList,
            startDate: this.getStartingPeriod,
            endDate: this.getEndingPeriod,
            type: this.periodType
          });
          localStorage.setItem('FirstTime','0');
        }
    } // refreshPage
    ,
    startAutoRefresh() {
      this.refreshInterval = setInterval(() => {
        if (!this.lastTimeStamp) {
          this.refreshPage();

          localStorage.setItem("reload","1");
          this.fetchDemographicsFidaAll({
            storeCodes: this.getStoreCodesList,
            startDate: this.getStartingPeriod,
            endDate: this.getEndingPeriod,
            type: this.periodType
          });
          localStorage.setItem('FirstTime','0');

        }

      }, 2000); // 5000 milliseconds = 5 seconds

    },
    clearAutoRefresh() {
      // Clear the auto-refresh interval if it exists
      if (this.refreshInterval) {
        clearInterval(this.refreshInterval);
      }
    },
  } // methods
}); // export default
